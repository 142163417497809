.entry-content>* {
    margin-bottom: 0 !important;
}

a {
    text-decoration: none !important;
}

#header {
    [data-row*=top] {
        background-image: linear-gradient(90deg, var(--paletteColor2, #DF1819) 0%, #700c0d 100%);
    }
    [data-row*=middle] {
        ul.menu {
            li {
                &:last-child {
                    a {
                        padding-right: 0 !important;
                    }
                }
            }
        }
    }
}

.ct-language-switcher {
    ul.ct-language {
        li {
            a {
                width: 29px;
                height: 29px;
                border: 2px solid #FFFFFF;
                background-color: transparent;
                border-radius: 100%;
                justify-content: center;
                &:hover {
                    background-color: #FFFFFF;
                }
            }
        }
        li.current-lang {
            a {
                width: 29px;
                height: 29px;
                background-color: #FFFFFF;
            }
        }
    }
}

.img-radius {
    img {
        border-radius: 10px;
    }
}

.img-shadow {
    img {
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.16);
    }
}

.con-shadow {
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.16);
}

.shadow-c {
    box-shadow: 9px 8px 15px rgba(0, 0, 0, 0.16);
}

.b-white {
    a {
        border-color: #FFFFFF !important;
        padding: 5px 22px !important;
        &:hover {
            background-color: #FFFFFF !important;
            color: #52525b !important;
        }
    }
}

.c-margin {
    .gb-inside-container {
        margin-left: 0 !important;
    }
}

.c-GF {
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.16);
}

.buttons-space {
    gap: 25px
}

.b-red {
    a {
        border-color: #DF1819 !important;
        padding: 5px 22px !important;
        &:hover {
            background-color: #DF1819 !important;
            color: #FFFFFF !important;
        }
    }
}

.b-red2 {
    a {
        border-color: #a91510 !important;
        padding: 5px 22px !important;
        &:hover {
            background-color: #a91510 !important;
            color: #FFFFFF !important;
        }
    }
}

.d-c {
    .gb-inside-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        .gb-button-wrapper {
            margin-top: auto;
            a.b-icon-color {
                &:hover {
                    svg {
                        path {
                            fill: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
}

.hero-home {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        display: block;
        width: 100%;
        height: 88px;
        background: url(/wp-content/uploads/2023/05/Path_282.png);
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 9;
    }
}

#gform_wrapper_2 {
    .gform_heading {
        p.gform_required_legend {
            display: none;
        }
    }
    .gform_body {
        .gform_fields {
            #field_2_6 {
                // .gfield_label {

                // }
                .ginput_container {
                    .gfield_checkbox {
                        display: flex;
                        .gchoice {
                            width: 100%;
                            // input {
                            //     label {

                            //     }
                            // }
                        }
                    }
                }
            }
        }
    }
    .gform_footer {
        // 
        display: none !important;
        // 
    }
}

.downloads-buttons {
    a.gb-button {
        justify-content: left
    }
}

.style-list {
    padding-left: 7px !important;
    margin: 0 !important;
    list-style: none;
    li {
        position: relative;
        padding-left: 24px;
        &:before {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            display: block;
            width: 9px;
            height: 15px;
            background: url(/wp-content/uploads/2023/05/arrowdown.png);
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}

.title-p {
    margin-bottom: 0 !important;
    line-height: 59px !important;
}

.s-c-shadow {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
}

.single-containers {
    .hero-section {
        margin-top: 25px;
    }
}

.ct-breadcrumbs {
    nav {
        a,
        span {
            font-size: 18px;
        }
    }
}

.c-filter {
    .facetwp-facet {
        margin-bottom: 0 !important;
        .facetwp-radio {
            text-align: center;
            background: none !important;
            background-size: 0 !important;
            margin-bottom: 0 !important;
            padding-left: 0 !important;
            .facetwp-display-value {
                font-family: 'Open Sans', Sans-Serif !important;
                font-weight: 700 !important;
                font-size: 25px !important;
                line-height: 34px;
                color: #DF1819 !important;
            }
            .facetwp-counter {
                display: none !important;
            }
        }
        .checked {
            .facetwp-display-value {
                color: #000000 !important;
            }
        }
    }
}

.facetwp-template[data-name="containers_posts"] {
    .fwpl-layout {
        column-gap: 34px;
        row-gap: 32px;
        .fwpl-result {
            background-color: #FFFFFF;
            box-shadow: 9px 8px 15px rgba(0, 0, 0, 0.16);
            .fwpl-row {
                .fwpl-col {
                    padding: 30px;
                    .featured-post {
                        text-align: center;
                    }
                    .title-post {
                        a {
                            font-weight: 700 !important;
                            font-size: 30px !important;
                            line-height: 39px;
                        }
                    }
                    .volume-post {
                        font-family: 'Open Sans', Sans-Serif !important;
                        font-weight: 700 !important;
                        font-size: 25px !important;
                        line-height: 34px;
                        color: #DF1819 !important;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

.custom-slider {
    .slick-slider {
        padding-bottom: 80px !important;
        .slick-list {
            .slick-track {
                .wp-block-getwid-content-slider-slide__wrapper {
                    .gb-container {
                        max-width: 517px;
                    }
                }
            }
        }
        .slick-dots {
            li {
                width: 26px;
                height: 13px;
                button {
                    width: 26px;
                    height: 13px;
                    padding: 0 !important;
                    &:before {
                        font-size: 0 !important;
                        opacity: 1 !important;
                        width: 26px;
                        height: 13px;
                        background-color: #FFFFFF;
                        border-radius: 10px;
    
                    }
                }
            }
            li.slick-active {
                width: 65px;
                height: 13px;
                button {
                    width: 65px;
                    height: 13px;
                    padding: 0 !important;
                    &:before {
                        font-size: 0 !important;
                        opacity: 1 !important;
                        width: 65px;
                        height: 13px;
                        background-color: #FFFFFF;
                        border-radius: 10px;
    
                    }
                }
            }
        }
    }
}

.bg-icon {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
        // width: 120px;
        // height: 120px;
        width: 23%;
        height: 25%;
        background: url(/wp-content/uploads/2023/05/Group-509.png);
        background-position: center center;
        background-size: contain;
        // background-size: cover;
        background-repeat: no-repeat;
    }
}

.ct-footer {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        right: 55px;
        bottom: 0;
        display: block;
        width: 638px;
        height: 603px;
        background: url(/wp-content/uploads/2023/05/cirkels-wit.png);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    [data-row="middle"] {
        .ct-container {
            .ct-widget {
                .widget-title {
                    margin-bottom: 50px !important;
                }
            }
        }
    }
    [data-row="bottom"] {
        .ct-container {
            max-width: 728px;
            padding-top: 0 !important;
            [data-column="widget-area-5"] {
                #block-3 {
                    .gform_wrapper {
                        position: relative;
                        .gform_body {
                            .gform_fields {
                                #field_3_1 {
                                    input {
                                        height: 64px;
                                        background-color: rgba(255,255,255,0.2);
                                        border-radius: 30px;
                                        border: 0;
                                        font-size: 20px !important;
                                        color: #FFFFFF !important;
                                        padding: 8px 200px 8px 35px !important
                                    }
                                    input::-webkit-input-placeholder {
                                        color: #FFFFFF !important;
                                        opacity: 1 !important;
                                    }
                                    
                                    input::-moz-placeholder {
                                        color: #FFFFFF !important;
                                        opacity: 1 !important;
                                    }
                                    
                                    input:-ms-input-placeholder {
                                        color: #FFFFFF !important;
                                        opacity: 1 !important;
                                    }
                                }
                            }
                        }
                        .gform_footer  {
                            position: absolute;
                            top: 0;
                            right: 0;
                            margin: 0 !important;
                            padding: 0 !important;
                            input {
                                min-height: 65px;
                                background-image: linear-gradient(90deg, var(--paletteColor2, #DF1819) 0%, #700c0d 100%);
                                font-size: 20px !important;
                                text-transform: none !important;
                                padding: 5px 42px !important;
                                margin-bottom: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.head-style {
    width: 264px;
}

article>.entry-content>*.alignfull {
    .gb-inside-container {
        width: var(--default-editor, var(--block-width));
        width: var(--container-width);
        margin-left: var(--default-editor, auto);
        margin-right: var(--default-editor, auto);
    }
}

article>.entry-content>*.gb-container-239433d9.alignfull {
    .gb-inside-container {
        // width: var(--container-width);
        width: 100% !important;
    }
}

.slider {
    display: block !important;
    .owl-item {
        opacity: 0.5 !important;
        .item {
            img {
                max-height: 770px;
                object-fit: cover;
                border-radius: 10px;
                margin-bottom: 35px;
            }
            h3 {
                font-family: 'Open Sans', Sans-Serif !important;
                font-weight: 600 !important;
                line-height: 41px !important;
                text-transform: uppercase;
                color: #FFFFFF;
                margin-top: 0 !important;
                margin-bottom: 7px !important;
            }
            h2 {
                font-family: 'Open Sans', Sans-Serif !important;
                line-height: 45px !important;
                color: #FFFFFF;
                margin-top: 0 !important;
                margin-bottom: 15px !important;
                min-height: 90px;
            }
            p {
                color: #FFFFFF;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }
        }
    }
    .owl-item.active {
        opacity: 1 !important;
    }
    .owl-nav {
        position: absolute;
        top: -39px;
        left: 0;
        right: 0;
        text-align: center;
        .owl-prev,
        .owl-next {
            width: 78px;
            height: 78px;
            background-color: #00B3CA !important;
            &:hover {
                background-color: #038C8C !important;
            }
        }
        .owl-prev {
            span {
                font-size: 0;
            }
        }
        .owl-next {
            span {
                font-size: 0;
            }
        }
    }
}

@include break-below(mdl) {
    .hero-parent {
        min-height: 550px !important;
    }
    .title-p {
        font-size: 30px;
        line-height: 40px !important;
    }
    .slider {
        .owl-item {
            .item {
                img {
                    margin-bottom: 15px;
                }
                .content {
                    h3 {
                        font-size: 25px;
                        line-height: 28px;
                    }
                    h2 {
                        font-size: 30px;
                        line-height: 35px !important;
                        min-height: 70px;
                    }
                }
            }
        }
    }
}

@include break-below(md) {
    .facetwp-template[data-name="containers_posts"] {
        .fwpl-layout {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 25px;
            row-gap: 25px;
            .fwpl-result {
                .fwpl-row {
                    .fwpl-col {
                        padding: 20px !important;
                        .title-post {
                            a {
                                font-size: 21px !important;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include break-below(sm) {
    .hero-home {
        min-height: 400px !important;
    }
    .space-style {
        height: 50px !important;
    }
    .slider {
        .owl-item {
            .item {
                img {
                    margin-bottom: 10px;
                }
                .content {
                    h3 {
                        font-size: 25px;
                        line-height: 28px;
                    }
                    h2 {
                        font-size: 30px;
                        line-height: 35px !important;
                        margin-bottom: 7px !important;
                    }
                }
            }
        }
    }
    .ct-footer [data-row="middle"] {
        .ct-container {
            .ct-widget {
                .widget-title {
                    margin-bottom: 20px !important;
                }
            }
        }
    }
    .ct-footer [data-row="bottom"] {
        .ct-container {
            [data-column="widget-area-5"] {
                #block-3 {
                    .gform_wrapper {
                        .gform_body {
                            .gform_fields {
                                #field_3_1 {
                                    input {
                                        height: 50px;
                                    }
                                }
                            }
                        }
                        .gform_footer {
                            input {
                                min-height: 50px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include break-below(xs) {
    .facetwp-template[data-name="containers_posts"] {
        .fwpl-layout {
            grid-template-columns: repeat(1, 1fr);
            column-gap: 0;
            row-gap: 20px;
            .fwpl-result {
                .fwpl-row {
                    .fwpl-col {
                        padding: 20px !important;
                        .title-post {
                            a {
                                font-size: 21px !important;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}